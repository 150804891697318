import React from 'react';
import { Page, Section, Button } from 'src/components';
import paths from 'src/constants/paths';
import { Segment, SegmentEvent } from 'src/lib';
import { isClient } from 'src/utils';

const Job = ({ pageContext }) => {
  const job = pageContext?.job;

  return (
    <Page hideBanner title={`${job?.title} at Catch`} path={`${paths.JOBS}/${job.slug}`}>
      <Section nav narrow large title={job?.title} subtitle={job?.location}>
        <article className="job article">
          <b1 dangerouslySetInnerHTML={{ __html: job?.description?.html }} />
        </article>
        <center>
          <br />
          <Button
            large
            onPress={() => {
              if (isClient()) {
                Segment.track(SegmentEvent.APPLIED_TO_JOB, {
                  userId: window.analytics?.user()?.anonymousId(),
                  page: window?.location.pathname,
                  job: job?.text,
                });

                window.location = job?.applyUrl;
              }
            }}
          >
            Apply
          </Button>
        </center>
      </Section>
    </Page>
  );
};

export default Job;
